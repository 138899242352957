<template>
	<div class="sandbox">
		<v-container>
			<h1 class="font-weight-light">Sandbox</h1>

			<v-text-field
				v-model="mail.subject"
				label="Sujet"
			></v-text-field>

			<h3 class="font-weight-regular mt-4">Header du mail</h3>
			<vue-editor v-model="mail.header" :disabled="!mail.header"></vue-editor>

			<h3 class="font-weight-regular mt-4">Corps du mail</h3>
			<vue-editor v-model="mail.content"></vue-editor>

			<h3 class="font-weight-regular mt-4">Footer du mail</h3>
			<vue-editor v-model="mail.footer" :disabled="!mail.footer"></vue-editor>

			<v-btn
				@click="go()"
				outlined
				class="my-4"
			>Envoyer</v-btn>

			<v-card class="my-4">
				<span>{{ entireMail }}</span>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	import {
		VueEditor
	} from "vue2-editor";

	export default {
		name: "Sandbox",
		data() {
			return {
				userInfo: this.$models.user,
				compInfo: this.$models.company,

				mail: {
					subject: 'Ceci est un sujet de mail',

					header: "",
					content: '<p>Lorem ipsum dolor sit amet, consectetur adip</p>',
					footer: ""
				}
			}
		},
		components: {
			VueEditor
		},
		methods: {
			go() {
				const recipients = {
					to: ['neant@infographart.com'],
					bcc: 'stan@infographart.com'
				}
				this.$katana.sendMail(this.compInfo.id, this.userInfo.id, this.mail.subject, this.entireMail, recipients);
			}
		},
		computed: {
			entireMail: function() {
				return this.mail.header + this.mail.content + this.mail.footer;
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).get().then((doc) => {
					this.compInfo = {
						...doc.data(),
						id: doc.id
					};

					this.mail.header = `<p style="text-align: center;" class="ql-align-center"><img src="${this.compInfo.avatar}" width="80"/></p><h2 style="text-align: center;" class="ql-align-center">${this.compInfo.name}</h2>`;
					this.mail.footer = `<p>Cordialement,</p><br/><p><span style="color: rgb(102, 163, 224);">${this.userInfo.firstName} ${this.userInfo.lastName}</span></p><p><strong>${this.userInfo.job} @ ${this.compInfo.name}</strong></p><p><span style="color: rgb(102, 163, 224);">Mobile.</span> ${this.userInfo.phone}</p><p><span style="color: rgb(102, 163, 224);">Web.</span> ${this.userInfo.website}</p>`;
				});
			});
		}
	}
</script>

<style>

</style>